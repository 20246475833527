/* NOTE: THIS FILE IS AUTOGENERATED - DO NOT EDIT IT DIRECTLY */

type SentryConfig =
  | {
      sentryEnabled: false;
    }
  | {
      sentryEnabled: true;
      sentryDSN: string;
      sentryEnvironment: string;
    };

export type Config = {
  gtmId: string;
  shopifyStoreDomain: string;
  shopifyStorefrontToken: string;
  twlBackendApiBaseUrl: string;
  twlReactApiBaseUrl: string;
  gReCaptchaKey: string;
  facebookOauth2ClientId: string;
  googleOauth2ClientId: string;
  googleReviewBadgeMerchantId: string;
  wisePopsSiteId: string;
  wisePopsNotificationsSiteId: string;
  cometlyUid: string;
  twlShopId: string;
} & SentryConfig;


export const config: Config = {"gtmId":"false","shopifyStoreDomain":"the-whisky-list-staging.myshopify.com","shopifyStorefrontToken":"623735e789a31b6912f5fe0004677147","twlBackendApiBaseUrl":"https://backend.twlstaging.creatio.com.au/api/v1","twlReactApiBaseUrl":"https://twlstaging.creatio.com.au/react/api/v1","gReCaptchaKey":"6Lf9pnggAAAAAOs2WZwi8vJpErHGUzTPv1mXaRrR","facebookOauth2ClientId":"554667986261953","googleOauth2ClientId":"1099458772892-hkj1fi61523aka7d0k45cunifa2b5it0.apps.googleusercontent.com","googleReviewBadgeMerchantId":"false","wisePopsSiteId":"YLnrZPspas","wisePopsNotificationsSiteId":"vW3z","cometlyUid":"false","twlShopId":"c6ef5fb4-632a-4c04-823e-0f1ddd973e55","sentryEnabled":true,"sentryDSN":"https://8dc41124f23c48d48dc137de82a78a28@o292781.ingest.sentry.io/6330194","sentryEnvironment":"staging"}